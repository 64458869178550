import React, { useState, useEffect, createRef } from "react";
import { createFileName, useScreenshot } from 'use-react-screenshot'
import { AiOutlineDownload } from "react-icons/ai";
import {WhatsappIcon, WhatsappShareButton, LinkedinShareButton, LinkedinIcon} from 'react-share';
import { FaDownload } from "react-icons/fa";
import pdf from "../Pdf/BPraveen_Kumar_UI.pdf";

import { Document, Page, pdfjs } from "react-pdf";
import {  Text, StyleSheet} from '@react-pdf/renderer'
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useCallback } from "react";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const resumeLink = pdf;

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });

const Resume = () => {
   /* const [width, setWidth] = useState(1200);
    useEffect(() => {
    setWidth(window.innerWidth);
   }, []); */
const shareUrl = "bpraveen.info";
const shareTitle = "Praveen Kumar";
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot(
    {
      type: "image/jpeg",
      quality: 1.0
    }
  );

  
  const [width, setWidth] = useState(300);
  
  

  const getImage = () => takeScreenshot(ref.current);
  console.log(getImage, 'maa')
 
  const [windowSize, setWindowSize] = useState([
    //window.innerHeight,
    window.innerWidth,
  ]);
  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize([window.innerWidth]);
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };
  useEffect(() => {}, []);

  //console.log(windowSize);
  
  const download = (image, {name = "praveen-img", extension= "png"} = {}) =>{
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  

  
  // console.log(download, 'hyd');

  const downloadScreenShort = () => takeScreenshot(ref.current).then(download);
  return (
    <>
    
      <div className=" bg-slate-200 pt-2 flex md:pt-24 lg:pt:24 sm:pt-32 dark:bg-black dark:text-white mx-auto mt-16 p-16 sm:mt-20 lg:mt-20">
        <div className=" flex flex-col justify-center">
        <p className="mt-2 text-3xl font-bold tracking-tight text-center text-white dark:text-white sm:text-4xl">
          
          <span className=" text-green-500 uppercase ">Resume</span>
        </p>
        <div className=" flex">
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={shareTitle}
          separator=":: "
          className=""
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
      <button style={{ marginBottom: "10px" }} onClick={getImage}>Get Image </button>
        <button type="button" onClick={downloadScreenShort}>Download ScreenShort</button>
        <img width={width} src={image} alt={"ScreenShot"} />
          <div className="  text-center m-2" ref={ref}>
            <Document
              file={resumeLink}
              className="justify-center"
              onLoadSuccess={onDocumentLoadSuccess}
            >
                
              <Page
                pageNumber={pageNumber}
                scale={windowSize >= 789 ? 1.6 : 0.5}
                className="justify-center"
              >
                {/* <Text style={styles.page} className="bg-black text-white w-96 fixed"  render={({ pageNumber, numPages }) => (
        `${pageNumber} / ${numPages}`
      )} /> */}
                


              </Page>
            </Document>
          </div>
          <div className=" flex-col row-end-2 justify-center">
           
            <button
            className="pdf-nav"
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
            hidden={pageNumber <= 1}
          >
            <span className="pr-btn ">⏪️ Previous</span>
          </button> <span className=" text-lg text-center"> Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"} </span>  
          <button
            className="pdf-nav"
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            hidden={numPages <= pageNumber }
          >
            <span className="pr-btn">Next ⏩️</span>
          </button>
          </div>
          <div className=" flex-col">
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={shareTitle}
          separator=":: "
          className=""
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
        </div>
        
        <div>
    
  </div>
      </div>
      {/* <div>

      <button
            className="pdf-nav"
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            <span className="pr-btn">⏪️ Previous</span>
          </button>
          <button
            className="pdf-nav"
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <span className="pr-btn">Next ⏩️</span>
          </button>
        <div className="page_number_sec">
          <h3>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </h3>
        </div>
        <div>
          <div fluid className="resume-section">
         
            <div style={{ justifyContent: "center", position: "relative" }}>
              <button
                type="button"
                variant="scondary"
                href={pdf}
                target="_blank"
                style={{ maxWidth: "250px" }}
              >
                <AiOutlineDownload />
                &nbsp;Download Resume
              </button>
            </div>

            <div className="resume">
              <h5>
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </h5>

              <Document
                file={resumeLink}
                className="d-flex justify-content-center"
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <button
                  className="pdf-nav"
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  <span className="pr-btn">⏪️ Previous</span>
                </button>
                <Page pageNumber={pageNumber} scale={width > 786 ? 1.7 : 0.6} />
                <button
                  className="pdf-nav"
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  <span className="pr-btn">Next ⏩️</span>
                </button>
              </Document>
              <h5>
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </h5>
            </div>

            <div style={{ justifyContent: "center", position: "relative" }}>
              <FaDownload
                className="ml-1 mr-1.5 h-5 w-5 text-white"
                aria-hidden="true"
              />
              Download Resume
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Resume;
