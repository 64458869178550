import React, { useEffect, useRef, useState } from "react";
import Card from "./Card";
import ProjectJsonUrl from '../Projects/Projects.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Projects = () => {
  const [projectsDataList, setProjectsDataList] = useState(ProjectJsonUrl);

  


  //const [filteredProjects, setFilteredProjects] = useState(projectsDataList.projects);
  const [filteredProjects, setFilteredProjects] = useState();
  const [filteredSkills, setFilteredSkils] = useState();

  const companyRef = useRef("");
  const skillRef = useRef("");

  // const getData = () => {
  //   fetch('Projects.json', {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setProjectsDataList(data);
  //       setFilteredProjects(data.projects);
  //     });
  // };
 

  const handleFilter = (event) => {
    const currentSkill = skillRef.current.value;
    const currentCompany = companyRef.current.value;

    let filteredResult = [];
    if (currentSkill === "all" && currentCompany === "all") {
      setFilteredProjects(projectsDataList.projects);
    }
    /* else{
      projectsDataList.projects.map((pr)=>{
        pr.skills.map((sk)=>{
          if(sk.name === currentSkill) {
            filteredResult.push(pr)}
        })
      })
      console.log(filteredResult)
      if(currentCompany === "all"){
        setFilteredProjects(filteredResult)
      }
      else{
        
        filteredResult = filteredResult.filter((fr)=>fr.type.includes(currentCompany))
      }
    } */
    if (currentSkill === "all" && currentCompany !== "all") {
      filteredResult = projectsDataList.projects.filter((fr) =>
        fr.type.includes(currentCompany)
      );

      setFilteredProjects(filteredResult);
    }
    if (currentSkill !== "all" && currentCompany === "all") {
    

      projectsDataList.projects.map((pr) => {
        pr.skills.map((sk) => {
          if (sk.name === currentSkill) {
            filteredResult.push(pr);
          }
        });
      });

      setFilteredProjects(filteredResult);
    }
    if (currentSkill !== "all" && currentCompany !== "all") {
    

      projectsDataList.projects.map((pr) => {
        const typename = pr.type.includes(currentCompany);
        pr.skills.map((sk) => {
          if (sk.name === currentSkill && typename) {
            filteredResult.push(pr);
          }
        });
      });

      setFilteredProjects(filteredResult);
    } 

    //const value = event.target.value;

    /*const skilResult = projectsDataList.projects.filter(

       (apiResultData) =>
        
          JSON.stringify(
            apiResultData.skills?.map(({ ...rest }) => {
              return rest;
            })
          )
            .toLocaleLowerCase()
            .includes(value.toLowerCase()) || apiResultData.type
            .toLocaleLowerCase()
            .includes(value.toLowerCase())
      ); */

    //setFilteredProjects();
    setFilteredSkils(filteredResult);
  };
  useEffect(() => {
    setFilteredProjects(projectsDataList.projects);
  }, []);


  return (
    <div className=" bg-custom-grey dark:bg-black dark:text-white mx-auto mt-16 p-4 sm:mt-20 lg:mt-20 ">
      <div className="flex justify-center p-8">
        <p className="mt-2 text-3xl font-bold tracking-tight text-center text-white dark:text-white sm:text-4xl">
          <span className=" text-custom-blue  uppercase "> completed</span>{" "}
          <span className=" text-green-500 uppercase ">Projects</span>
        </p>
      </div>

      <div className="flex justify-between ml-0 mr-0  md:ml-12 md:mr-12  lg:ml-12 md: lg:mr-12 rounded flex-row md:flex-row lg:flex-row xl:flex-row  pl-1 pr-1 md:pl-14 md:pr-14 lg:pl-14 lg:pr-14 bg-black">
        <div className=" hidden md:block lg:block"></div>
        <div className=" hidden md:block lg:block"></div>
        <div className=" col-end-1 place-content-end">
          <div className="m-5 md:inline-block ">
            <label
              htmlFor="Technology"
              className=" text-sm font-medium leading-6 text-white"
            >
              Filter By Technology
            </label>
            <select
              id="type-input-skills"
              onChange={handleFilter}
              name="skil"
              ref={skillRef}
              className=" w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value="all">All</option>
              <option value="ReactJs">React</option>
              <option value="JavaScript">Javascript</option>
              <option value="PHP">PHP</option>
              <option value="HTML5">HTML</option>
              <option value="CakePHP">CakePHP</option>
            </select>
          </div>

          <div className="m-5 md:inline-block ">
            <label
              htmlFor="Project Type"
              className=" text-sm font-medium leading-6 text-white"
            >
              Project Type
            </label>
            <select
              onChange={handleFilter}
              id="type-input"
              name="type"
              ref={companyRef}
              className=" w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value="all">All</option>
              <option value="Corporate">Corporate</option>
              <option value="ECommerce">ECommerce</option>
              <option value="AdminPortal">Admin Portal</option>
              <option value="Hospital">Hospital</option>
              <option value="Hotals">Hotals</option>
              <option value="Real Estate">Real Estate </option>
              <option value="Educational">Educational </option>
            </select>
          </div>
        </div>
      </div>
      {
        filteredProjects?.length !== 0 ?
      
      <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-3  md:grid-cols-2">
        {filteredProjects &&
          filteredProjects.map((project, index) => (
                  
            <Card projectData={project} key={index}></Card>
           
          ))}
         
      </div>
      :  <div className=" text-center">
           <p className="mt-2 text-3xl font-bold tracking-tight text-center text-white dark:text-white sm:text-4xl">
          <span className=" text-custom-blue  uppercase "> No</span>{" "}
          <span className=" text-green-500 uppercase ">Projects Found</span>
        </p>
      </div>
}

      {/* 
      <h1>NO Projects Found</h1>
        {projectsDataList.projects &&
          projectsDataList.projects.length > 0 &&
          projectsDataList.projects.map((project, index) => (
            <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-3  md:grid-cols-2">
            <Card projectData={project} key={index} />
            </div> 
          ))}
      */}
    </div>
  );
};

export default Projects;
