import React from 'react'
import TypewriterComponent from 'typewriter-effect';

const TypeText = () => {
  return (
    <TypewriterComponent
          options={{
            strings: [
              "Front End Developer, Passionate UI Developer. Committed Open Source Contributor, Effective Problem Solver.",
            ],
            autoStart: true,
            loop: true,
            deleteSpeed: 50,
          }}
        />
  )
}

export default TypeText