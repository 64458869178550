import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MyImage = (props) => (
    
  <div className=''> 
   
    <LazyLoadImage
      alt={props.imagetData}
     // height={image.height} width={image.width} 
      src={window.location.origin + "/projects/"+props.imagetData} // use normal <img> attributes as props
      effect="blur"
      className='rounded-t-lg z-50 relative aspect-video' 
    />
    {/* <span>{image.caption}</span> */}
  </div>
);

export default MyImage;
