import React from "react";
import {  RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import Home from "./Main/Home";
import Projects from "../Components/Projects/Projects";

import Aboutus from "./Main/Aboutus";
import Contactus from "./Main/Contactus";
// import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import DragAndDrop from "./Draganddrop";
import Resume from "./Pdf/Resume";
import ImagesZoom from "./Pdf/ImagesZoom";



function Main() {
  const HeaderLayout = () => (
    <>

    <Header />
      <Outlet /> 
      <Footer/>
    </>
  )

    const appRouter = createBrowserRouter([
      {
      element: <HeaderLayout />,
      children:[
        {
            path: "/",
            element: <Home />

        },
        {
            path: "/aboutus",
            element: <Aboutus />

        },
        {
            path: "/projects",
            element: <Projects />

        },
        {
          path: "/contactus",
          element: <Contactus />

      },
      {
        path: "/contactus",
        element: <Contactus />

    },
    {
      path: "/resume",
      element: <Resume />

  },

  {
    path: "/imagezoom",
    element: <ImagesZoom />

},
{
  // path: "/phoneNumberInput",
  // element: <PhoneNumberInput />

},
      {
        path: "/draganddrop",
        element: <DragAndDrop />

    },
    ],
  },
])

  return (
    <>
    <div className=" mt-20 md:mt-16 sm:mt-16">
    
      <RouterProvider  router={appRouter}  />
    </div>

    </>
  );
}

export default Main;
