
 import './App.css';

import Main from './Components/Main'

function App() {
 
  return (
    <div className="App bg-white dark:bg-black">
      <Main />
      
    </div>
  );
}

export default App;
