import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ThemeSwitcher from "../ThemeSwitcher";
import {
 
  Bars3BottomRightIcon,
  XMarkIcon,
  CodeBracketIcon,
} from "@heroicons/react/24/solid";

function Header() {
  let pageLinks = [
    { id: 1, name: "HOME", to: "/" },
    { id: 2, name: "ABOUT", to: "/aboutus" },
    { id: 3, name: "PROJECTS", to: "/projects" },
    { id: 4, name: "CONTACT US", to: "/contactus" },
  ];
  let [open, setOpen] = useState(false);

  return (
    <>
    
      <div className="shadow-md w-full fixed top-0 left-0 dark:border-b-2 z-50 border-indigo-600}">
        <div className="md:flex items-center justify-between bg-white dark:bg-black   py-4 md:px-10 px-7">
          {/* logo section */}
          <div className=" hidden md:block lg:block xl:block 2xl:block">
            <div className="font-bold text-2xl cursor-pointer flex items-center gap-1">
            <NavLink to={'/'}>  <CodeBracketIcon className="w-7 h-7 text-blue-600" /></NavLink> 
            <NavLink to={'/'}> <span className=" text-black dark:text-white">Praveen</span></NavLink>
            </div>
            {/* Menu icon */}
          </div>

          {/* Mobile Nav   */}
          <div className="flex md:hidden lg:hidden xl:hidden 2xl:hidden  flex-row">
            <div className="flex-none ...">
             <NavLink to={'/'} ><CodeBracketIcon className="w-7 h-7 text-blue-600" /></NavLink> 
            </div>
            <div className="flex-1 w-32">
            <NavLink to={'/'} > <span className=" text-black dark:text-white">Praveen</span></NavLink> 
            </div>
            <div className="flex-1 w-32">
              <ThemeSwitcher />
            </div>
            <div
              className="flex-1 w-10 h-12  absolute right-8 top-3 items-center dark:text-white"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <XMarkIcon className=" dark:text-white" />
              ) : (
                <Bars3BottomRightIcon />
              )}
            </div>
          </div>

          {/* linke items */}
          <ul
            className={` dark:bg-black  bg-white md:bg-none lg:bg-none md:flex  md:items-center absolute md:static  md:z-auto z-[-1] left-0 w-full md:w-auto  transition-all duration-500 ease-in ${
              open ? "top-12" : "top-[-490px] "
            }`}
          >
            {pageLinks.map((pageLink) => (
              <li
                className=" md:my-0 font-semibold  dark:bg-black bg-white"
                key={pageLink.id}
              >
                <Link
                  to={pageLink.to}
                  key={pageLink.id}
                  className="text-gray-800 dark:text-white hover:bg-none hover:border-b-4 hover:border-green-600 hover:text-blue-400 duration-500"
                >
                  {pageLink.name}
                </Link>
              </li>
            ))}
            <div className="font-semibold   duration-500 p-0 m-0 px-0 mx-0  hidden md:block lg:block xl:block 2xl:block ">
              <ThemeSwitcher />
            </div>
          </ul>
        </div>
        
      </div>
    </>
  );
}

export default Header;
