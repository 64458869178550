import React, { useRef, useState } from "react";
import {
  
  CodeBracketSquareIcon,
  ServerIcon,
  DevicePhoneMobileIcon
} from "@heroicons/react/20/solid";
import emailjs from '@emailjs/browser';

const features = [
  {
    name: "Designing.",
    description:
      "Design is one of the most powerful forces in our lives, whether or not we are aware of it, and can also be inspiring, empowering and enlightening.",
    icon: DevicePhoneMobileIcon,
  },
  {
    name: "Developing.",
    description:
      "Growth and development are important because they are essential for individuals and societies to thrive and reach their full potential. Growth refers to physical changes and expansion, while development refers to the acquisition of skills, knowledge, and capabilities.",
    icon: CodeBracketSquareIcon,
  },
  {
    name: "Hosting Services.",
    description:
      "A hosting service stores your website and all its important files and data on a server it provides, which enables the connection needed so visitors can access your website via the internet.",
    icon: ServerIcon,
  },
];

const  Contactus =() => {

  const formData = useRef();
  const [status, setStatus]  = useState("");
  const [showElement, setShowElement] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_hqlz0ob', 'template_a39ybp9', formData.current, '5wkcZHsNKyuAUeJLj')
      .then((result) => {
        console.log('send inside', showElement);
        setStatus({ type: 'success' });
        setShowElement(true);
        formData.current.reset();
      //   window.alert(
      //     "Email Sent Will Get Back Soon!🎉"
      // );
      setTimeout(function () {
        setShowElement(false);
        console.log(showElement, 'praveen')
        
      }, 5000);
         
      }, (error) => {
          
          console.log(error.text);
      });
  };

const handleClear =() =>{
  formData.current.reset();
}


  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32 dark:bg-black dark:text-white">
      <div className="flex mx-auto max-w-7xl px-6 lg:px-8 flex-col lg:flex-row ">
        <div className="flex-1 ">
          <div className="lg:max-w-lg">
            <h2 className="text-base font-semibold leading-7 text-indigo-600 ">
              Let’s get to work
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            <span className=" text-green-500 uppercase ">GET</span> IN TOUCH
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
              We are Doing Designing, Developing, implementing, and testing in
              client/server e-business, and agriscience applications using
              ReactJS, Angular, Vue and Web technologies, I am Providing Hosting
              Services also.
            </p>

            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 dark:text-white lg:max-w-none">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900 dark:text-white">
                    <feature.icon
                      className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>{" "}
                  <dd className="inline dark:text-white">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="flex-1 ">
          <form id="contact-form" ref={formData} onSubmit={sendEmail}>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  Connect with your via text
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                 CONTACT <span className=" text-green-500 uppercase ">ME</span>
                </p>
                <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-white ">
                  Use a calid eamil addres where you can receive mail.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="full-name"
                      className="block text-sm font-medium leading-6 text-gray-900  dark:text-white "
                    >
                      Full name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                         name="user_name"
                        id="full-name"
                        autoComplete="given-name"
                        required 
                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="user_email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="mobile"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                    >
                    Mobile Number
                    </label>
                    <div className="mt-2">
                      <input
                        name="contact_number"
                        type="number"
                        autoComplete="mobile"
                        step="0.01"
                        maxLength={11}
                        
                        required
                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-white "
                    >
                      About
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="about"
                        name="message"
                        rows={3}
                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600 dark:text-white">
                      Write a few sentences about yourself.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
            

              <button onClick={handleClear}
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
              >
                Cancel
              </button>
              <button
                type="submit" 
                value="Send"
                className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Submit
              </button>
              
            </div>
            
          </form>
        {status?.type === 'success' &&  showElement ?
          <div className=" text-green-900 text-xl "><p>Email Sent Will Get Back Soon!🎉</p></div>: ''

          }
      
      {/* {status?.type === 'success' && showElement ? (
           <div className=" text-green-900 text-xl "><p>Email Sent Will Get Back Soon!🎉</p></div>
        ) : (
          <div></div>
        )}{" "} */}
        </div>
        
      </div>
    </div>
  );
}

export default Contactus;
