import React from "react";
// import TypeText from "./TypeText";
import aboutImage from "../../assets/images/about.jpg";
import develoiperImage from "../../assets/images/develope.jpg";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <div className=" bg-white pt-2 md:pt-24 lg:pt:24 sm:pt-32 dark:bg-black dark:text-white mx-auto mt-16 p-16 sm:mt-20 lg:mt-20">
      <div className="flex mx-auto max-w-7xl md:px-6  sm:p-0 sm:m-0  lg:px-8 flex-col lg:flex-row relative ">        
        <div className="flex-1 ">
          <div className="lg:max-w-lg drop-shadow-2xl">
            <h2 className="text-base font-semibold leading-7 text-green-600">
              <span>Hi There! </span>
              <span className=" animate-wiggle text-xl md:text-3xl lg:text-3xl ">👋🏻 </span>
            </h2>
            <p className="mt-2 text-2xl md:text-4xl capitalize lg:text-4xl font-bold font-font-three  tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              I'm<span className=" text-custom-blue drop-shadow shadow-green-500 dark:text-white "> Praveen Kumar Bandi</span> 
            </p>
            <p className="mt-2 text-2xl drop-shadow shadow-red-500  trcking-tight font-mono  text-green-900  dark:text-white  sm:text-2xl">
              Web End Developer
            </p>

            <div className="social-sec inline-grid grid-cols-3 gap-4 m-3 ">
              <span>
                <a
                  href="https://www.linkedin.com/in/bandipraveenkumar/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="linkedin"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    className=" w-8 h-8  text-custom-blue  dark:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                  </svg>
                </a>
              </span>
              <span>
                <a
                  href="https://github.com/bpraveendrpt"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="github"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8 text-custom-blue  dark:text-white"
                  >
                    <path d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5 64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9 26.4 39.1 77.9 32.5 104 26 5.7-23.5 17.9-44.5 34.7-60.8-140.6-25.2-199.2-111-199.2-213 0-49.5 16.3-95 48.3-131.7-20.4-60.5 1.9-112.3 4.9-120 58.1-5.2 118.5 41.6 123.2 45.3 33-8.9 70.7-13.6 112.9-13.6 42.4 0 80.2 4.9 113.5 13.9 11.3-8.6 67.3-48.8 121.3-43.9 2.9 7.7 24.7 58.3 5.5 118 32.4 36.8 48.9 82.7 48.9 132.3 0 102.2-59 188.1-200 212.9a127.5 127.5 0 0 1 38.1 91v112.5c.8 9 0 17.9 15 17.9 177.1-59.7 304.6-227 304.6-424.1 0-247.2-200.4-447.3-447.5-447.3z"></path>
                  </svg>
                </a>
              </span>
            </div>
            <p className=" sm: mt-5 md:mt-6 lg:mt-6 text-xl  font-bold  font-mono text-lime-700 dark:text-white">
              Passionate UI Developer. Committed Open Source Contributor,
              Effective Problem Solver
            </p>
            <p>
              As a UI developer, I enjoy tackling new challenges and
              continuously expanding my skillset. I am proficient in ReactJS and
              PHP, Javascript and HTML5.
            </p>
            {/* <NavLink to={'/aboutus'}><button class="mt-3 animate-border bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded">
  More About me
</button></NavLink> */}
            <NavLink to={"/aboutus"}>
              <button className="mt-4 inline-block rounded-md bg-white bg-gradient-to-r from-green-500 via-blue-500 to-orange-500 bg-[length:400%_400%] p-1">
                <span className="block rounded-md bg-slate-900 px-5 py-3 font-bold text-white">
                
                  More About me
                </span>
              </button>
            </NavLink>
          </div>
          
        </div>
        <div className=" flex  dark:block">
            <div className="sun  dark:block"></div>
            <div className="earth bg-green-500 before:border-dashed before:border-blue-950  before:border-2 dark:before:border-white ">
                <div className="moon bg-gray-700  dark:bg-white"></div>
            </div>
        </div>
        <div className="flex-1 mt-10 md:mt-2 lg:mt-2  ">
          <div
            className="bg-cover bg-center w-full h-auto   "
            style={{
              backgroundImage: "url(" + aboutImage + ")",
            }}
          >
            <div className=" bg-black  border-r-8 border-t-8 border-green-700 ">
              <img
                src={develoiperImage}
                alt="home pic"
                className="img-fluidhome-page border-r-8 border-t-8  border-green-800 animate-border   w-80  lg:-mt-28 lg:-mr-12 lg:absolute md:absolute md:top-9 md:right-0 lg:top-9 lg:right-0 h-96"
              />
              <img
                src={aboutImage}
                alt="home pic"
                className="img-fluid home-page  w-full h-90"
              />
            </div>
            
          </div>
          
        </div>
       
        
      </div>
      
    </div>
  );
}

export default Home;
